<template>
    <div style="width: 100%;">
        <div class="mt-4">
            <b-button variant="primary"  @click.prevent="showNewItemForm" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t(`questionnaire.btn_answeroption_new`) }}</b-button>

            <br />
            <div class="table-scrollable">
            <b-table striped hover :items="items" :fields="fields">
                <template #cell(actions)="data">
                    <b-link class="m-1" href="#" @click="editItem(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                </template>
            </b-table>
            </div>
            <br />
            <b-modal ref="confirmationModal" hide-footer :title="$t(`questionnaire.delete_title`)">
                <div class="d-block text-center">
                    <p v-if="itemContext">{{ $t(`questionnaire.delete_message`, {title: itemContext.answertext}) }}</p>
                </div>
                <b-button class="mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t(`questionnaire.delete_button`) }}</b-button>
            </b-modal>
        </div>
        <Editansweroption ref="Editansweroption" :savedCallback="reload"  />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Editansweroption from './Editansweroption';
    import QuestionnaireService from '../../../../../services/QuestionnaireService';

    export default {
        name: 'answeroptions',
        components: {Editansweroption},
        props: ['items', 'questionId'],
        data() {
            return {
            }
        },
        created() {
        },
        methods: {
            ...mapActions({
                deleteAnsweroptions: 'answeroptions/delete'
            }),
            showNewItemForm() {
                this.currentItemEditMode = false;
                this.$refs.Editansweroption.setItem(this.questionId, {});
                this.$refs.Editansweroption.showModal();
            },
            editItem(item) {
                this.$refs.Editansweroption.setItem(this.questionId, item);
                this.$refs.Editansweroption.showModal();
                this.$refs.Editansweroption.enterEditMode();
            },
            requestConfirmation(item) {
                this.itemContext = item;
                this.$refs.confirmationModal.show();
            },
            executeDelete() {
                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                QuestionnaireService.deleteAnsweroption(this.questionId, this.itemContext.id)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Entfernen der Antwortoption.',
                                type: 'error',
                                text: response.data.message
                            });
                        }

                        this.itemContext = null;
                        this.reload();
                    });
            },
            reload() {
                this.$store.commit('loading/setLoading', true);
                QuestionnaireService.fetchAnsweroptions(this.questionId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.items = response.data;
                });
            },
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                answeroptions: 'answeroptions/answeroptions',
                questionnaires: 'questionnaires/questionnaires'
            }),
            fields() {
                return [
                    { key: 'order',                         label: 'Nr'           , sortable: true  },
                    { key: 'answer_text',                   label: 'Antwort '                       },
                    { key: 'answer_score',                  label: 'Punkte '                        },
                    { key: 'conditional_question_id',       label: 'Bedingte Frage '                },
                    { key: 'actions',                       label: 'Aktionen'                       },
                ];
            },
        }
    }
</script>