<template>
    <div>
        <b-card class="mb-3 shadow">
            <b-alert show v-if="questionnaire.requesttext" variant="info">
                <b-form-group v-if="questionnaire.requesttext" label-for="questionnaire-requesttext">
                    <b-row class="ml-1" v-html="newlineToBr(questionnaire.requesttext)" id="questionnaire-requesttext"></b-row>
                </b-form-group>
            </b-alert>
            <h3>{{questionnaire.name}}</h3>
            <b-form @submit.prevent="submitForm()">
                <template v-for="question in questionnaire.questions">
                    <b-form-group class="mt-4" v-if="showQuestion(question.id)" >
                        <label v-if="question.type !== 7">
                            <span v-html="newlineToBr(question.questiontext)"></span>{{ question.required ? '*' : '' }} <span><sup>{{ question.id }}</sup></span>
                            <b-icon v-b-modal="'collapse-' + question.id" v-if="question.question_note !== '' && question.question_note !== null" style="cursor: pointer;" icon="info-circle" shift-v="2" font-scale="1.2" variant="primary"></b-icon>
                            <b-modal :id="'collapse-' + question.id" centered ok-only>
                                <p class="mt-1 text-muted">{{question.question_note}}</p>
                            </b-modal>
                        </label>
                        <label v-if="question.type === 7" class="w-100 my-3">
                            <b-alert show variant="success" class="p-5">
                                <span v-html="newlineToBr(question.questiontext)"></span>
                                <b-icon v-b-modal="'collapse-' + question.id" v-if="question.question_note !== '' && question.question_note !== null" style="cursor: pointer;" icon="info-circle"></b-icon>
                            </b-alert>
                            <b-modal :id="'collapse-' + question.id" centered>
                                <p class="mt-1 text-muted">{{question.question_note}}</p>
                            </b-modal>
                        </label>
                        <template v-if="question.type === 1">
                            <b-form-input type="number" :name="'question-'+question.id" v-model.trim="answers[question.id]" :state="$v['answers'][question.id].$error ? false : null"/>
                        </template>
                        <template v-if="question.type === 2">
                            <b-form-input type="number" :name="'question-'+question.id" v-model.trim="answers[question.id]" :state="$v['answers'][question.id].$error ? false : null"/>
                        </template>
                        <template v-if="question.type === 3">
                            <b-form-input type="text" :name="'question-'+question.id" v-model.trim="answers[question.id]"  :state="$v['answers'][question.id].$error ? false : null"/>
                        </template>
                        <template v-if="question.type === 4">
                            <b-form-file :name="'question-'+question.id" v-model="answers[question.id]" placeholder="Datei wählen..."></b-form-file>
                            <b-button v-if="answers[question.id+'_downloadKey']" @click.prevent="downloadFile(answers[question.id+'_downloadKey'], answers[question.id+'_filename'], answers[question.id+'_mimetype'])">Datei herunterladen</b-button>
                        </template>
                        <template v-if="question.type === 5 && question.answeroptions.length != 0">
                            <b-form-radio-group class="ml-3" stacked :name="'question-'+question.id" v-model.trim="answers[question.id]">
                                <template v-for="value in question.answeroptions">
                                    <b-form-radio :value="value.id">{{ value.answer_text }}</b-form-radio>
                                </template>
                            </b-form-radio-group>
                        </template>
                        <template v-if="question.type === 6 && question.answeroptions.length != 0">
                            <b-form-checkbox-group class="ml-3" stacked :name="'question-'+question.id" v-model="answers[question.id]">
                                <template v-for="value in question.answeroptions">
                                    <b-form-checkbox :value="value.id">{{ value.answer_text }}</b-form-checkbox>
                                </template>
                            </b-form-checkbox-group>
                        </template>
                    </b-form-group>
                </template>
                <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("questionnaire.btn_save") }}</b-button>
                <b-button class="button-margin" v-if="(questionnaire.request == 0 || questionnaire.request == 1)" @click.prevent="downloadQuestionnaire(questionnaire.id)"><b-icon icon="download" />{{ $t("questionnaire.btn_download") }}</b-button>
                <br />
            </b-form>
        </b-card>
        <br />
    </div>
</template>
<script>
import questionnaireService from '../../../../services/QuestionnaireService';
import { helpers, required } from 'vuelidate/lib/validators';
import DownloadService from '../../../../services/DownloadService';

export default {
    name: 'profile-questionnaire',
    props:['questionnaire', 'answers'],
    data() {
        return {
            file: {},
        }
    },
    validations() {
        let validations = {
            answers: {}
        };

        this.questionnaire.questions.forEach(question => {
            validations['answers'][question.id] = {};

            if(question.required) {
                validations['answers'][question.id]['required'] = required;
            }

            if(question.check_regex) {
                const customValidator = (value) => {
                    if(value === undefined || value === null) {
                        return true;
                    }

                    return new RegExp(question.check_regex).test(value);
                }

                validations['answers'][question.id]['regex'] = customValidator;
            }

            if(question.check_eval) {
                    const customValidator = (value) => {
                    if(value === undefined || value === null || value === '') {
                        return true;
                    }

                    return eval(''+value+question.check_eval);
                }

                validations['answers'][question.id]['eval'] = customValidator;
            }
        });

        return validations;
    },
    created() {
    },
    methods: {
        submitForm() {
            this.$v.$touch();

            if(this.$v.$error) {
                this.$notify({
                            group: 'global',
                            title: this.$t("user.notify_questionnaire_check"),
                            type: 'error',
                            text: this.$t("user.notify_questionnaire_checkmessage")
                        });
                return;
            }

            let values = {};

            this.questionnaire.questions.forEach(q => {
                values[q.id] = this.answers[q.id] ?? '';
            });

            questionnaireService.saveQuestions(this.questionnaire.id, values)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.label_success"),
                            type: 'success',
                            text: this.$t("questionnaire.label_message")
                        });
                    }
                })
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("questionnaire.label_error"),
                        type: 'error',
                        text: this.$t("questionnaire.message_error")
                    });
                });
        },
        showQuestion(id) {
            let isConditional = false;
            let conditionFulfilled = false;
            this.questionnaire.questions.forEach(question => {
                switch(question.type)
                {
                    case 2:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                // Überprüfen, ob der Inhalt der Antwort nicht leer ist
                                if (this.answers[question.id]) {
                                    conditionFulfilled = true; // Zeige die Frage, wenn der Inhalt nicht leer ist
                                }
                            }
                        });
                        break;
                    case 3:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                // Überprüfen, ob der Inhalt der Antwort nicht leer ist
                                if (this.answers[question.id] && (this.answers[question.id].trim() !== '')) {
                                    conditionFulfilled = true; // Zeige die Frage, wenn der Inhalt nicht leer ist
                                }
                            }
                        });
                        break;
                    case 5:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                conditionFulfilled = conditionFulfilled ||  (this.answers[question.id] == answeroption.id);
                            }
                        });
                        break;
                    case 6:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                if (this.answers[question.id]) 
                                {
                                    conditionFulfilled = conditionFulfilled || (this.answers[question.id].includes(answeroption.id));
                                }
                            }
                        });
                        break;
                    default:
                        break;
                }
            });
            return (!isConditional) || conditionFulfilled;
        },
        downloadQuestionnaire(questionnaireId) {
            questionnaireService.downloadQuestionnaire(questionnaireId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fragebogen.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadFile(downloadKey, filename, mimetype) {
            questionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        },
        newlineToBr: function (value) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
    },
    computed: {
        fieldState() {
            return questionId => this.$v['question-'+questionId].$error ? null : false;
        },
    }
};
</script>