<template>
    <div>
        <b-card class="mb-3 shadow">
            <h3>{{questionnaire.name}}</h3>
            <template v-for="question in questionnaire.questions">
                <b-row class="my-1">
                    <b-col sm="8"><label>{{question.questiontext}}</label></b-col>
                    <b-col sm="4">
                        <template v-if="question.type === 1">
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 2">
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 3">
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 4">
                            <b-link v-if="answers[question.id+'_downloadKey']" @click.prevent="downloadFile(answers[question.id+'_downloadKey'], answers[question.id+'_filename'], answers[question.id+'_mimetype'])">{{ answers[question.id+'_filename'] }}</b-link>
                        </template>
                        <template v-if="question.type === 5">
                            {{ answers[question.id] }}
                        </template>
                        <template v-if="question.type === 6">
                            {{ answers[question.id] }}
                        </template>
                    </b-col>
                </b-row>
            </template>
            <!--<b-button class="button-margin" @click.prevent="downloadQuestionnaire(questionnaire.id)">{{ $t("questionnaire.btn_download") }}</b-button>-->
            <br />
        </b-card>
    </div>
</template>
<script>
import questionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';

export default {
    name: 'profile-questionnaire-answers',
    props:['questionnaire', 'answers'],
    created() {
    },
    methods: {
        downloadQuestionnaire(questionnaireId) {
            questionnaireService.downloadQuestionnaire(questionnaireId)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: 'application/pdf'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'fragebogen.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentElement.removeChild(link);
                });
        },
        downloadFile(downloadKey, filename, mimetype) {
            questionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        }
    }
};
</script>
