<template>
    <b-card title="Lieferantenrisiken" class="mb-3 shadow">

        <div class="table-scrollable">
            <b-table striped hover
            :items="supplierrisks"
            :fields="fields"
            responsive="sm"
            >
            </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'supplierrisktab',
    components: {},
    props: [],
    data() {
        return {
            supplierrisks: [],
            selectedYear: null,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSupplierRisks();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSupplierRisks() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSupplierRisks()
                    .then(response => {
                        this.supplierrisks = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        fields() {
            return [
                //{ key: 'ID', sortable: true },
                { key: 'year.year', label: 'Jahr', sortable: true },
                { key: 'display_name', label: 'NACE', sortable: true },
                { key: 'sector.sector_name', label: 'Branche', sortable: true },
                { key: 'sector_score.score', label: 'Branchenrisiko', sortable: true },
                { key: 'country.country_name', label:'Land', sortable: true },
                { key: 'country_score.score', label:'Länderrisiko', sortable: true },
                //{ key: 'questionnaire_score.score', label: 'Fragebogen', sortable: true },
                //{ key: 'sector_risk_sum', label: 'Risiko Branche', sortable: true },
            ];
        },
    },
}
</script>