<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t("project.listheader") }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-card :title="$t(`project.card_list`)" class="mb-3 shadow">
                <b-button v-b-toggle.help_projects pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                <b-sidebar right id="help_projects" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                    <div class="px-3 py-2">
                        <b-list-group>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_1") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_2") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_3") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_4") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_5") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_6") }}</b-list-group-item>
                            <b-list-group-item class="user-select-all">{{ $t("help.projects_7") }}</b-list-group-item>                            
                        </b-list-group>
                    </div>
                </b-sidebar>
                <b-button variant="primary" class="button-margin mb-3" to="/app/project/new">{{ $t("project.btn_project_new") }}</b-button>
                <div class="table-scrollable">
                    <b-table striped hover :items="projects" :fields="fields">
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.id)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" v-show="data.index < projects.length-1" @click="moveItemDown(data.item.id)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="'/app/project/view/' + data.item.id" ><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="'/app/project/edit/' + data.item.id" ><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#"  @click.prevent="requestConfirmation(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                        </template>
                    </b-table>
                </div>
                <br />
            </b-card>
        </div>
    <b-modal ref="confirmationModal" hide-footer :title="$t(`project.modal_delete`)">
        <div class="d-block text-center">
            <p v-if="projectContext">{{ $t("project.modal_deleteproject1") }} <strong>'{{projectContext.project_title}}'</strong>{{ $t("project.modal_deleteproject2") }}</p>
        </div>
        <b-button class="mt-3" variant="outline-danger" block @click="executeDelete">{{ $t("project.btn_delete") }}</b-button>
    </b-modal>
</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import numberHelper from '../../../../helpers/numberHelper';
import { dateHelper } from '../../../mixins/dateHelper';
import ProjectService from '../../../../services/ProjectService';

export default {
    name: 'project-list',
    mixins: [dateHelper],
    data() {
        return {
            projectContext: null,
        }
    },
    created() {
        this.fetchProjects();
    },
    watch: {
        '$route': 'fetchProjects'
    },
    methods: {
        ...mapActions({
            fetchProjects: 'projects/fetchProjects',
            deleteProject: 'projects/delete'
        }),
        requestConfirmation(project) {
            this.projectContext = project;
            this.$refs.confirmationModal.show();
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.deleteProject(this.projectContext.id)
                .then(data => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("project.notify_delete_title"),
                        type: 'success',
                        text: this.$t("project.notify_delete_message")
                    });

                    this.projectContext = null;
                });
        },
        moveItemUp(projectId) {
            this.$store.commit('loading/setLoading', true);
            ProjectService.moveProjectUp(projectId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchProjects();
                });
        },
        moveItemDown(projectId) {
            this.$store.commit('loading/setLoading', true);
            ProjectService.moveProjectDown(projectId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchProjects();
                });
        }
    },
    computed: {
        ...mapGetters({
            projects: 'projects/projects'
        }),
        fields() {
            return [
                { key: 'actions',                           label: 'Aktionen' },
                { key: 'id',                                label: 'Id '                                    , sortable: true },
                { key: 'project_created_by.display_name',   label: 'Erstellt durch '                        , sortable: true },
                { key: 'created_at',                        label: 'Erstellt am '                           , sortable: true , formatter: this.dateFormatter },
                { key: 'updated_at',                        label: 'Geändert '                              , sortable: true , formatter: this.dateFormatter },
                { key: 'project_number',                    label: 'Projektnummer '                         , sortable: true },
                { key: 'project_title',                     label: 'Projektname '                           , sortable: true },
                { key: 'order_number',                      label: 'Reihenfolge'                            , sortable: true },
                { key: 'year',                              label: 'Jahr '                                  , sortable: true },
                { key: 'period',                            label: 'Periode '                               , sortable: true },
                { key: 'company',                           label: 'Gesellschaft '                          , sortable: true },
                { key: 'location',                          label: 'Standort '                              , sortable: true },
                { key: 'businessunit',                      label: 'Geschäftsbereich '                      , sortable: true },
                { key: 'department',                        label: 'Abteilung '                             , sortable: true },
                { key: 'account',                           label: 'Konto '                                 , sortable: true },
                { key: 'account_number',                    label: 'Kontonummer '                           , sortable: true },
                { key: 'category_level1',                   label: 'Obergruppe '                            , sortable: true },
                { key: 'category_level2',                   label: 'Kostengruppe '                          , sortable: true },
                { key: 'category_level3',                   label: 'Untergruppe '                           , sortable: true },
                { key: 'category_level4',                   label: 'Subgruppe '                             , sortable: true },
                { key: 'commoditygroup',                    label: 'Artikelgruppe '                         , sortable: true },
                { key: 'purchasing_volume',                 label: 'Basisvolumen '                          , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'potential_abs_onetime',             label: 'Potenzial abs. einmalig '               , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'potential_abs_recurring',           label: 'Potenzial abs. wiederkehrend '          , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'potential_perc_onetime',            label: 'Potenzial % einmalig '                  , sortable: true },
                { key: 'potential_perc_recurring',          label: 'Potenzial % wiederkehrend '             , sortable: true },
                { key: 'potential_startdate',               label: 'Beginn Potenzial '                      , sortable: true , formatter: this.dateFormatter },
                { key: 'potential_enddate',                 label: 'Ende Potenzial '                        , sortable: true , formatter: this.dateFormatter },
                { key: 'savings_abs_onetime',               label: 'Einsparung abs. einmalig '              , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'savings_abs_recurring',             label: 'Einsparung abs. wiederkehrend '         , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'savings_perc_onetime',              label: 'Einsparung % einmalig '                 , sortable: true },
                { key: 'savings_perc_recurring',            label: 'Einsparung % wiederkehrend '            , sortable: true },
                { key: 'savings_startdate',                 label: 'Beginn Effekte '                        , sortable: true , formatter: this.dateFormatter },
                { key: 'savings_enddate',                   label: 'Ende Effekte '                          , sortable: true , formatter: this.dateFormatter },
                { key: 'liquidityeffect_abs_onetime',       label: 'Liquiditätseffekt abs. einmalig '       , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'liquidityeffect_abs_recurring',     label: 'Liquiditätseffekt abs. wiederkehrend '  , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'budget_planned',                    label: 'Budget Plan '                           , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'budget_revised',                    label: 'Budget revidiert '                      , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'budget_actual',                     label: 'Budget IST '                            , sortable: true , formatter: value => { return numberHelper.toFormattedStringWithThousands(value); }},
                { key: 'concept_ideas',                     label: 'Konzeptideen Beschreibung '             , sortable: true },
                { key: 'concept_approaches',                label: 'Konzeptansätze Beschreibung '           , sortable: true },
                { key: 'solution_description',              label: 'Lösungsmodell Beschreibung '            , sortable: true },
                { key: 'notes',                             label: 'Bemerkungen '                           , sortable: true },
                { key: 'comments',                          label: 'Kommentare '                            , sortable: true },
                { key: 'tags',                              label: 'Tags '                                  , sortable: true },
                { key: 'problems',                          label: 'Problemstellungen '                     , sortable: true },
                { key: 'responsible',                       label: 'Verantwortlich '                        , sortable: true },
                { key: 'priority',                          label: 'Priorität '                             , sortable: true },
                { key: 'satus_implementation',             label: 'Umsetzungsstatus '                      , sortable: true },
                { key: 'team',                              label: 'Team '                                  , sortable: true },
                { key: 'projectphase',                      label: 'Projektphase '                          , sortable: true },
                { key: 'relevance',                         label: 'Relevanz '                              , sortable: true },
                { key: 'projectassignment',                 label: 'Projektzuordnung '                      , sortable: true },
                { key: 'label',                             label: 'Label '                                 , sortable: true },
                { key: 'archived',                          label: 'Archiviert '                            , sortable: true },
                { key: 'reportingcircle',                   label: 'Berichtskreis '                         , sortable: true },
                { key: 'reportingcycle',                    label: 'Berichtszyklus '                        , sortable: true }
            ];
        },
    }
};
</script>
