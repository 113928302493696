<template>
    <div>
        <b-card class="mb-3 shadow" :title="$t(`risk.card_countries`)">
            <b-button variant="primary"  @click.prevent="showNewItemForm" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t("risk.btn_country_new") }}</b-button>
            <b-button variant="primary" @click.prevent="showImport" class="button-margin" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="file-earmark-arrow-down" />{{ $t("risk.btn_import") }}</b-button>

            <br />
            <b-form-group horizontal :label="$t(`risk.search`)" class="mb-0">
                <b-input-group>
                    <b-input v-model="searchFilter" :placeholder="$t(`risk.search_country_placeholder`)" />
                    <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t("risk.search_empty") }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <br />
            <div class="table-scrollable">
            <b-table striped hover :items="countries" :fields="fields" :filter="searchFilter">
                <template #cell(actions)="data">
                    <b-link class="m-1" href="#" @click="editItem(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                </template>
            </b-table>
            </div>
            <br />
            <b-modal ref="confirmationModal" hide-footer :title="$t(`risk.modal_title_delete`)">
                <div class="d-block text-center">
                    <p v-if="itemContext">{{ $t(`auction_list.${type}.delete_message`, {title: itemContext.country_iso_3166}) }}</p>
                </div>
                <b-button class="mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t("risk.btn_delete") }}</b-button>
            </b-modal>
        </b-card>
        <Editcountry ref="Editcountry" :savedCallback="reload" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Editcountry from './Editcountry';
    import CountryService from '../../../../../services/Riskmanagement/CountryService';

    export default {
        name: 'countries',
        components: {Editcountry},
        props: [],
        data() {
            return {
                searchFilter: null,
                itemContext: null,
            }
        },
        created() {
            this.fetchCountries();
        },
        methods: {
            ...mapActions({
                fetchCountries: 'countries/fetch',
                deleteCountries: 'countries/delete'
            }),
            showNewItemForm() {
                this.currentItemEditMode = false;
                this.$refs.Editcountry.setItem({});
                this.$refs.Editcountry.showModal();
            },
            editItem(item) {
                this.$refs.Editcountry.setItem(item);
                this.$refs.Editcountry.showModal();
                this.$refs.Editcountry.enterEditMode();
            },
            requestConfirmation(item) {
                this.itemContext = item;
                this.$refs.confirmationModal.show();
            },
            executeDelete() {
                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                CountryService.delete(this.itemContext.id)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Entfernen des Landes.',
                                type: 'error',
                                text: response.data.message
                            });
                        }

                        this.itemContext = null;
                        this.reload();
                    });
            },
            reload() {
                this.fetchCountries();
            },
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                countries: 'countries/countries'
            }),
            fields() {
                return [
                    { key: 'id',                    label: 'Id'             , sortable: true  },
                    { key: 'country_iso_3166',      label: 'Code'           , sortable: true  },
                    { key: 'country_name',          label: 'Land'           , sortable: true  },
                    { key: 'actions',               label: 'Aktionen'                         },
                ];
            },
        }
    }
</script>