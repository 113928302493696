<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group deck>
            <b-card title="Ulrich Zschenderlein "
                img-src="images/team/Zschenderlein.jpg"
                img-top>
                <p class="card-text">
                    Vice President Supply Chain Management<br/><br/>
                    <a href="mailto: ulrich.zschenderlein@tadano.com">ulrich.zschenderlein@tadano.com</a><br/>
                    Tel. +49 173 394 5908
                </p>
            </b-card>
            <b-card title="Stefan Altmann"
                img-src="images/team/stefan-altmann.jpg"
                img-top>
                <p class="card-text">
                    Head of Strategic Purchasing<br/><br/>
                    <a href="mailto: stefan.altmann@tadano.com">stefan.altmann@tadano.com</a><br/>
                    Tel. +49 9123 185 157
                </p>
            </b-card>
            <b-card title="Stefanie Jettkant"
                img-src="images/team/stefanie-jettkant.jpg"
                img-top>
                <p class="card-text">
                    Senior Strategic Buyer<br/><br/>
                    <a href="mailto: stefanie.jettkant@tadano.com">stefanie.jettkant@tadano.com</a><br/>
                    Tel. +49 6332 83 2765
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <b-card-group deck>
            <b-card title="Jannik Weisdorfer-Ehl"
                img-src="images/team/jannik-weisdorfer.jpg"
                img-top>
                <p class="card-text">
                    Strategic Buyer Indirect Procurement<br/><br/>
                    <a href="mailto: jannik.weisdorfer-ehl@tadano.com">jannik.weisdorfer-ehl@tadano.com</a><br/>
                    Tel. +49 6332 83 2619
                </p>
            </b-card>
            <b-card title="André Grotstabel"
                img-src="images/team/Grotstabel.jpg"           
                img-top>
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen<br/><br/>
                    <a href="mailto: andre.grotstabel@ebnerstolz.de">andre.grotstabel@ebnerstolz.de</a><br/>
                    Tel. +49 173 250 1031
                </p>
            </b-card>
            <b-card title="Florian Frost"
                img-src="images/team/Frost.jpg"           
                img-top>
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen<br/><br/>
                    <a href="mailto: florian.frost@ebnerstolz.de">florian.frost@ebnerstolz.de</a><br/>
                    Tel. +49 174 176 7921
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>